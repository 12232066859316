// On staging, NODE_ENV === 'production'.
// Netlify functions will pull from NODE_ENV_FOR_FUNCTIONS.
let buildEnvironment = process.env.NODE_ENV ?? process.env.NODE_ENV_FOR_FUNCTIONS;
if (process.env.VUE_APP_STAGING) buildEnvironment = 'staging';
buildEnvironment ??= 'development';

export const BUILD_ENVIRONMENT = String(buildEnvironment);

const initialLocationSearch = typeof location !== 'undefined' ? new URL(location.href).searchParams : null;
const queriedEnvironmentOverride = initialLocationSearch?.get('dangerously-override-environment');
const queriedApiHostOverride = initialLocationSearch?.get('dangerously-override-api-host');

export const API_HOST_OVERRIDE = queriedApiHostOverride;

export const ENVIRONMENT_IN_USE = queriedEnvironmentOverride ?? BUILD_ENVIRONMENT;

let clientOrigin = typeof location !== 'undefined' ? location.origin : '';
const IS_IOS = clientOrigin.startsWith('capacitor:');
const IS_ANDROID = clientOrigin === 'http://localhost';
if (IS_IOS || IS_ANDROID) {
    clientOrigin = {
        staging: 'https://staging.iseechange.com',
        production: 'https://www.iseechange.com',
    }[BUILD_ENVIRONMENT] ?? clientOrigin;
}

export const CLIENT_ORIGIN = clientOrigin;

if (queriedApiHostOverride) {
    console.warn(`Using API host from query: ${queriedApiHostOverride}`);
}

const CLIENT_WILL_PROXY_THE_API = CLIENT_ORIGIN;
export const API_HOST = queriedApiHostOverride ?? CLIENT_WILL_PROXY_THE_API;
export const BASE_API_URL = `${API_HOST}/api/v1`;

const NLP_API_HOSTS: any = {
    // development: 'http://localhost:5000',
    development: 'https://nlp.staging.iseechange.org',
    staging: 'https://nlp.staging.iseechange.org',
    production: 'https://nlp.iseechange.org',
};

export const NLP_API_HOST = NLP_API_HOSTS[ENVIRONMENT_IN_USE];

const WEATHER_API_HOSTS: any = {
    development: 'https://weatheranalytics.staging.iseechange.org',
    staging: 'https://weatheranalytics.staging.iseechange.org',
    production: 'https://weatheranalytics.iseechange.org',
};

export const WEATHER_API_HOST = WEATHER_API_HOSTS[ENVIRONMENT_IN_USE];

const TRACKER_ORIGINS: { [env: string]: string } = {
    development: 'https://localhost.staging.iseechange.com:8181',
    staging: 'https://staging.tracker.iseechange.com',
    production: 'https://tracker.iseechange.com',
} as const;

export const TRACKER_ORIGIN = process.env.VUE_APP_TRACKER_ORIGIN || TRACKER_ORIGINS[ENVIRONMENT_IN_USE];


const MIXPANEL_TOKENS: any = {
    development: '8a47aad0321f86eb28ff226b4adf9886',
    staging: '8a47aad0321f86eb28ff226b4adf9886',
    production: 'baf6da0b933f0e12aa952d5103f8425c',
};

// Prefer the origin, if we're on an https site (i.e. not in a native app).
// TODO: Replace .org with .com when appropriate.
export const APPLE_OAUTH2_REDIRECT_URL = (typeof location !== 'undefined' && location.origin.includes('https')) ? location.origin : 'https://www.iseechange.org';

// Export a string even if the token isn't set to prevent everything from breaking.
// https://github.com/mixpanel/mixpanel-js/issues/133#issuecomment-371623167
export const MIXPANEL_TOKEN = MIXPANEL_TOKENS[ENVIRONMENT_IN_USE] ?? 'NONE';

export const PRISMIC_ENDPOINT = 'https://iseechange.cdn.prismic.io/api/v2';

export const SENTRY_DSN = 'https://129eeba370484771b0bfc757affb25ab@sentry.io/1512033';
export const SENTRY_ENVIRONMENT = ENVIRONMENT_IN_USE;

export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiaXNlZWNoYW5nZSIsImEiOiJjam56OW5pdHoxY3BuM2trYmhhd25qc2dkIn0.yatwcNpjawzL30woc8_NKg';

export const ANDROID_APP_LINK = 'https://play.google.com/store/apps/details?id=gov.nasa.jpl.iseechangeapp';
export const IOS_APP_LINK = 'https://geo.itunes.apple.com/us/app/iseechange-tracker/id1114885557?mt=8';

export const ISEECHANGE_TRENDS_USER_ID = 'ELnXERdOkzH2vqyNn93AYsJk';
