import store from '@/store';
import type { Location, NavigationGuard, RouteConfig } from 'vue-router';
import RouteNames from './names';

function authSpecificRedirect(notSignedInLocation: Location['path'], signedInLocation: Location['path']) {
    const beforeEnter: NavigationGuard = async (to, from, next) => {
        await store.state.account.currentSessionCheck;
        if (store.state.account.currentUser) {
            next({ path: signedInLocation, query: to.query });
        } else {
            next({ path: notSignedInLocation, query: to.query });
        }
    };
    return beforeEnter;
}

const clientRedirects: RouteConfig[] = [{
    path: '/COREsurvey',
    redirect: () => ({
        path: '/sightings',
        query: {
            survey: 'mdc-dem-core-membership',
            near: '-80.191788,25.761681,20mi',
        },
    }),
}, {
    path: '/cityofmiami',
    beforeEnter: authSpecificRedirect('/campaigns/miami?ref=CoM', '/projects/city-of-miami/flooding'),
}, {
    path: '/mosboston',
    beforeEnter: authSpecificRedirect('/partners/museum-of-science', '/projects/museum-of-science-boston/NECS'),
}, {
    path: '/oceanconservancy',
    beforeEnter: authSpecificRedirect('/partners/miamiCAP', '/projects/ocean-conservancy/miamiCAP'),
}, {
    path: '/PEP',
    beforeEnter: authSpecificRedirect('/welcome/healthy-gulf/PEP', '/projects/healthy-gulf/PEP'),
}, {
    path: '/SOUL',
    beforeEnter: authSpecificRedirect('/welcome/SOUL/SOUL', '/projects/SOUL/SOUL'),
}, {
    path: '/GRD',
    beforeEnter: authSpecificRedirect('/welcome/GRD/gentillyresilience', '/projects/GRD/gentillyresilience'),
}, {
    path: '/jhrand',
    beforeEnter: authSpecificRedirect('/projects/NOLA-HEAT/research', '/projects/NOLA-HEAT/research'),
}, {
    path: '/neworleans',
    name: RouteNames.LANDING_NEW_ORLEANS,
    component: () => import(/* webpackChunkName: "campaign" */ '@/pages/NolaRainLanding.vue'),
}, {
    path: '/miamidade',
    redirect: { name: RouteNames.POST_CREATE },
}, {
    path: '/whitby',
    redirect: { name: RouteNames.PARTNER_LANDING_PAGE, params: { partner: 'whitby' } },
}, {
    path: '/rain',
    redirect: { name: RouteNames.LANDING_NEW_ORLEANS },
}, {
    path: '/girl(-)?scouts',
    redirect: { name: RouteNames.PARTNER_LANDING_PAGE, params: { partner: 'girl-scouts' } },
}, {
    path: '/(mos-boston|boston-mos)', // I can never remember which way it goes.
    redirect: { name: RouteNames.PARTNER_LANDING_PAGE, params: { partner: 'museum-of-science' } },
}];

export default clientRedirects;
